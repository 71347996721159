import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, Switch,  Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import { getData } from '../utils/dataLoader';
import './SiteDashboard.css';
import TopNavBar from './TopNavBar';
import HeaderBar from './HeaderBar';
import { Brightness3, WbSunny, Save as SaveIcon, ViewList as ViewListIcon, Sort as SortIcon } from '@mui/icons-material';

import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { CSSTransition } from 'react-transition-group';

import { db, auth } from '../utils/firebase'; 
import { doc, onSnapshot, getDoc, getDocs, updateDoc, serverTimestamp, arrayUnion, query, where, addDoc, collection } from "firebase/firestore";

import { Note } from '@mui/icons-material';

import LoadingScreen from './loadingScreen';

ChartJS.register(ArcElement, Tooltip, Legend);

const SiteDashboard = () => {
  const { siteName: paramSiteName  } = useParams();
  const location = useLocation();
  const siteName = paramSiteName || location.state?.siteName;
  const [isVisualMode, setIsVisualMode] = useState(true); // Default to visual mode

  const [siteData, setSiteData] = useState(null);  // Holds site data
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);  // Error state
  const [selectedDate, setSelectedDate] = useState(null);  // Holds selected date
  const [availableDates, setAvailableDates] = useState([]);  // Available date folders
  const [siteConfig, setSiteConfig] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'inverterBlock', direction: 'ascending' }); // Sorting configuration
  const [impactedKW, setImpactedKW] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const [energyRate, setEnergyRate] = useState(0.12); 
  const [kWPerPanel, setKWPerPanel] = useState(0.5); // Set default value of kW per panel

  const [tooltipVisible, setTooltipVisible] = useState(true); // Controls tooltip visibility
  const [tooltipData, setTooltipData] = useState(null); // Data for the tooltip
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 }); // Tooltip position
  const handleToggleTooltip = () => setTooltipVisible(!tooltipVisible);

  const [expandedLevel, setExpandedLevel] = useState(null);
  const totalPanels = siteData?.panelCount || 0;

  const [clickCount, setClickCount] = useState(0);

  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');

  const [siteNotes, setSiteNotes] = useState('');
  const [siteNotesList, setSiteNotesList] = useState([]);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const [isInfoHovered, setIsInfoHovered] = useState(false);


  const toggleNotesVisibility = () => {
    setIsNotesVisible(!isNotesVisible);
  };

  const handleSectionClick = () => {
    setClickCount(prevCount => prevCount + 1);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  };

  const sortedNotes = [...siteNotesList].sort((a, b) => {
    const timeA = a.timestamp?.seconds || 0;
    const timeB = b.timestamp?.seconds || 0;
    return sortOrder === 'desc' ? timeB - timeA : timeA - timeB;
  });

  const getFilteredLevelsData = () => {
    return Object.entries(siteData.groupedDataByLevel).map(([level, categories]) => ({
      level: level.replace('%20', ' '), // Clean up the text to display "Level 1" etc.
      categories: Object.entries(categories)
        .filter(([, affectedPanels]) => affectedPanels > 0)
        .map(([categoryName, affectedPanels]) => ({ categoryName, affectedPanels })),
    }));
  };

  const getAffectedPercentage = (levelData) => {
    const affectedPanels = levelData.categories.reduce((sum, { affectedPanels }) => sum + affectedPanels, 0);
    const percentOfSite = ((affectedPanels / totalPanels) * 100).toFixed(2);
    return { affectedPanels, percentOfSite };
  };

  const toggleExpandLevel = (level) => {
    setExpandedLevel(expandedLevel === level ? null : level);
  };

  const calculateCircles = (totalPanels, affectedPanels) => {
    const totalCircles = 50;
    let redCircles = Math.round((affectedPanels / totalPanels) * totalCircles);
    if (affectedPanels > 0 && redCircles === 0) {
      redCircles = 1;
    }
    return {
      redCircles,
      greenCircles: totalCircles - redCircles,
    };
  };

  const [notes, setNotes] = useState(() => {
    // Retrieve saved notes from local storage (or API)
    return localStorage.getItem('siteNotes') || '';
  });
  
  const saveNotes = async () => {
    if (!siteName || !siteNotes.trim()) {
      alert("Notes cannot be empty!");
      return;
    }
  
    try {
      // Fetch the current user's name and role from Firestore
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is logged in.");
        return;
      }
  
      const q = query(collection(db, 'userAccounts'), where('email', '==', user.email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.error("No user data found for the logged-in user.");
        return;
      }
  
      let author = "Unknown";
      querySnapshot.forEach((docSnap) => {
        const userData = docSnap.data();
        author = `${userData.name} - ${userData.role}`;
      });
  
      // Reference to the `SiteNotes` collection
      const siteNotesRef = collection(db, "sites", siteName, "SiteNotes");
      
      // Add the note to Firestore with the author field
      await addDoc(siteNotesRef, {
        content: siteNotes,
        timeStamp: serverTimestamp(),
        author: author,
      });
  
      console.log("Note added successfully.");
      setSiteNotes(""); // Clear the input after saving
    } catch (error) {
      console.error("Error saving note:", error.message);
    }
  };
  

  const handleMouseMove = (e, row) => {
    if (!tooltipVisible) return;
  
    // Set tooltip data and adjust position
    setTooltipData(row);
  
    const tooltipWidth = 150; // Approximate width of the tooltip
    const tooltipHeight = 80; // Approximate height of the tooltip
    const offsetX = 20; // Distance from the cursor horizontally
    const offsetY = 20; // Distance from the cursor vertically
  
    // Calculate the tooltip position based on mouse coordinates
    let left = e.clientX + offsetX;
    let top = e.clientY + offsetY;
  
    // Adjust if the tooltip would overflow the viewport
    if (left + tooltipWidth > window.innerWidth) {
      left = e.clientX - tooltipWidth - offsetX;
    }
    if (top + tooltipHeight > window.innerHeight) {
      top = e.clientY - tooltipHeight - offsetY;
    }
  
    setTooltipPosition({ top, left });
  };
  
  useEffect(() => {
    console.log('Click count:', clickCount);
  
    // Timeout to reset click count if no clicks happen within 1 second
    if (clickCount > 0) {
      const timer = setTimeout(() => {
        console.log("Resetting click count due to timeout");
        setClickCount(0); // Reset if no click within 1 second
      }, 1000);
  
      // Clear the timeout if another click occurs within 1 second
      return () => clearTimeout(timer);
    }
  }, [clickCount]);


  //FOR THE HEADER BAR__________________________________________________________=-=-=-=-=-
  useEffect(() => {
    console.log('Sitename in sitedashboard: ', siteName); 
    if (!siteName) return;

    // Set up a real-time listener for Firebase changes
    const siteDocRef = doc(db, "sites", siteName);

    const unsubscribe = onSnapshot(siteDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const newKWPerPanel = data.customFields?.panelProd || 0.5;
        const newEnergyRate = data.customFields?.kwValue || 0.12;

        setKWPerPanel(newKWPerPanel); // Update kW per panel dynamically
        setEnergyRate(newEnergyRate); // Update energy rate dynamically
      } else {
        console.error(`No document found for site: ${siteName}`);
      }
    });

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, [siteName]);


  //FOR DA NOTES -=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=-==-=-=-=-=
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const siteDocRef = doc(db, "sites", siteName);
        const siteSnapshot = await getDoc(siteDocRef);
  
        if (siteSnapshot.exists()) {
          const siteData = siteSnapshot.data();
          console.log("Raw siteData.notes:", siteData.notes); 
          setSiteNotesList(siteData.notes || []); // Populate the notes list from Firestore
        } else {
          console.error("No site document found.");
        }
      } catch (error) {
        console.error("Error fetching notes:", error.message);
      }
    };
  
    fetchNotes();
  }, [siteName]);

  useEffect(() => {
    console.log('Click count:', clickCount);
    
    if (clickCount === 3) {
      const metricItems = document.querySelectorAll('.metric-item');
      metricItems.forEach((item, index) => {
        console.log(`Adding wave and wave-delay-${index} to item`);
        item.classList.add('wave', `wave-delay-${index}`);
      });
  
      // Remove the wave effect classes after the animation completes
      setTimeout(() => {
        metricItems.forEach((item, index) => {
          console.log(`Removing wave and wave-delay-${index} from item`);
          item.classList.remove('wave');
          item.classList.remove(`wave-delay-${index}`);
        });
        setClickCount(0); // Reset click count
      }, 1000); 
    }
  }, [clickCount]);

  const PVWATTS_API_URL = 'https://developer.nrel.gov/api/pvwatts/v6.json';
  const PVWATTS_API_KEY = process.env.REACT_APP_PVWATTS_API_KEY;

  const navigate = useNavigate();

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  const fetchPVWattsData = async (lat, lng, systemSize) => {
    try {
      const response = await axios.get(PVWATTS_API_URL, {
        params: {
          api_key: PVWATTS_API_KEY,
          lat: lat,
          lon: lng,
          system_capacity: systemSize,
          module_type: 0,
          losses: 10,
          array_type: 2,
          tilt: 0,
          azimuth: 180,
        },
      });
      return response.data.outputs;
    } catch (error) {
      console.error('Error fetching PVWatts data:', error);
      return null;
    }
  };

  const formatDate = (dateString) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    if (typeof dateString === 'string' && dateString.length === 8) {
      const month = parseInt(dateString.slice(0, 2), 10) - 1;
      const day = parseInt(dateString.slice(2, 4), 10);
      const year = parseInt(dateString.slice(4), 10);

      return `${monthNames[month]} ${day}, ${year}`;
    } else {
      return dateString; 
    }
  };

  // Fetch site config and data
  useEffect(() => {
    const fetchSiteConfig = async () => {
      const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}`;
      const siteConfigUrl = `${baseUrl}/siteConfig.json`;

      try {
        const response = await fetch(siteConfigUrl);
        const configData = await response.json();
        const dateFolders = configData.dateFolders || [];

        if (dateFolders.length > 0) {
          setAvailableDates(dateFolders);
          setSelectedDate(dateFolders[0].date); // Default to the first available date
        }
        setSiteConfig(configData); // Store config in state
      } catch (error) {
        console.error('Error fetching siteConfig:', error);
      }
    };

    fetchSiteConfig();
  }, [siteName]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    console.log("Selected date:", newDate); // Debugging log
  };


  const handleFaultDataNavigation = () => {
    if (!selectedDate) {
        console.error("No date selected");
        return;
    }

    navigate(`/fault-data`, { state: { siteName, selectedDate } }); // Ensure the selected date is passed
  };


  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  

  useEffect(() => {
    // Calculate total panels affected and percent affected
    if (siteData) {
      const totalPanelsAffected = Object.values(siteData.effectedPanelsByRectangleId).reduce((sum, affected) => sum + affected, 0);
      const percentAffected = ((totalPanelsAffected / siteData.panelCount) * 100).toFixed(2);
      
      const impactedKWValue = (totalPanelsAffected * kWPerPanel).toFixed(2);
      setImpactedKW(impactedKWValue);

      setSiteData(prevData => ({
        ...prevData,
        totalPanelsAffected,
        percentAffected,
      }));
    }
  }, [siteData, kWPerPanel]);

  useEffect(() => {
    const calculateKWhLost = async () => {
      if (siteData && impactedKW > 0) {
        const { lat, lng } = siteData.center;

        // Make the API call to PVWatts using impacted kW as the system size
        const pvData = await fetchPVWattsData(lat, lng, impactedKW);
        
        if (pvData) {
          setSiteData(prevData => ({
            ...prevData,
            kWhLost: pvData.ac_annual.toFixed(2), // Assuming ac_annual contains the estimated annual kWh
          }));
        }
      }
    };

    calculateKWhLost();
  }, [impactedKW, siteData]);

  // Handle kW per panel input changes
  const handleKWPerPanelChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setKWPerPanel(value);
  };

  const revenueLost = siteData?.kWhLost ? (siteData.kWhLost * energyRate).toFixed(2) : null;
  //HEY LOOK OVER HERE-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=

  useEffect(() => {
    if (selectedDate && siteConfig) {
      const selectedDateConfig = siteConfig.dateFolders.find(dateFolder => dateFolder.date === selectedDate);

      if (!selectedDateConfig) {
        console.error('No config found for the selected date');
        setError('No config found for the selected date');
        setLoading(false);
        return;
      }

      const { layers, IB, levels } = selectedDateConfig;

      const fetchData = async () => {
        const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/${encodeURIComponent(selectedDate)}`;
        try {
          const data = await getData(baseUrl, layers, IB, levels);  // Pass the configs to getData
          setSiteData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching site data:', error);
          setError('Failed to fetch site data');
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [siteName, selectedDate, siteConfig]);

  // Pie chart data construction
  const generatePieData = () => {
    if (!siteData || !siteData.groupedDataByLevel) return null;

    const faultCategories = [];

    // Collect affected panels from all fault categories
    Object.entries(siteData.groupedDataByLevel).forEach(([level, categories]) => {
      Object.entries(categories).forEach(([categoryName, affectedPanels]) => {
        if (affectedPanels > 0) { // Only include categories with affected panels
          const decodedCategoryName = decodeURIComponent(categoryName); // Replace %20 with spaces
          faultCategories.push({
            category: decodedCategoryName,
            affected: affectedPanels,
          });
        }
      });
    });

    // Pie chart dataset with only affected panels
    const pieData = {
      labels: faultCategories.map(c => c.category), // Labels are the fault categories
      datasets: [
        {
          data: faultCategories.map(c => c.affected), // Data is the number of affected panels for each category
          backgroundColor: ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff', '#f56942'], 
          hoverOffset: 4,
        },
      ],
    };

    return pieData;
  };
  
  if (loading) {
    return <LoadingScreen />;
  }

  if (!siteData) {
    return <p>No data available for this site.</p>;
  }

  const sortTable = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting the table rows based on the current sort configuration
  const sortedTableData = (tableData) => {
    return [...tableData].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle numerical sorting for inverter blocks (IB1, IB2...) and percentages
      if (sortConfig.key === 'inverterBlock') {
        aValue = parseInt(a.inverterBlock.replace('IB', '')); // Extract the number part of IB1, IB2
        bValue = parseInt(b.inverterBlock.replace('IB', ''));
      } else if (sortConfig.key === 'percentageAffected') {
        aValue = parseFloat(a.percentageAffected); // Convert percentages to floats
        bValue = parseFloat(b.percentageAffected);
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  // Construct the data table
  const generateTableData = () => {
    return Object.entries(siteData.effectedPanelsByRectangleId).map(([blockId, affectedPanels]) => {
      const totalPanels = siteData.rectangles.find(rect => rect.id === blockId)?.pCount || 0;  // Get the total panels for the inverter block
      const percentageAffected = ((affectedPanels / totalPanels) * 100).toFixed(2);

      return {
        inverterBlock: blockId,
        totalPanels,
        affectedPanels,
        percentageAffected,
      };
    });
  };

  // Navigate to the interactive map (Home.js)
  const handleMapNavigation = () => {
    navigate(`/home/${encodeURIComponent(siteName)}`);
  };

  const toggleNotesModal = async () => {
    if (!isNotesModalOpen) {
      try {
        const siteNotesRef = collection(db, "sites", siteName, "SiteNotes"); // Adjust path as per your Firestore
        const querySnapshot = await getDocs(siteNotesRef);
  
        // Map the retrieved documents to an array
        const notesList = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Unique document ID
          ...doc.data(), // Spread the data fields
        }));
  
        setSiteNotesList(notesList); // Set the notes in state
      } catch (error) {
        console.error("Error fetching notes:", error.message);
      }
    }
  
    setIsNotesModalOpen(!isNotesModalOpen); // Toggle modal visibility
  };  
  

  const tableData = sortedTableData(generateTableData());

  return (
    <div className="page-layout">

{/*Top bar sections */}
      {/*Top bar sections */}
            {/*Top bar sections */}

      <HeaderBar siteName={siteName} selectedDate={selectedDate}/>
      <TopNavBar siteName={siteName} selectedDate={selectedDate} />

      {/* Tag to reveal Notes */}
      <div className="notes-tag" onClick={toggleNotesVisibility} title="Click to view notes">
        <Note style={{ marginRight: '5px' }} /> {/* Notepad icon */}
        <span className="arrow">&#9664;</span> {/* Left arrow */}
      </div>

      {/* Notes Card */}
      <div className={`dashboard-notes ${isNotesVisible ? 'open' : ''}`}>
          <h2>Leave A Note</h2>
          <textarea
            value={siteNotes}
            onChange={(e) => setSiteNotes(e.target.value)}
            placeholder="Write your notes here..."
            className="notes-textarea styled-textarea"
          ></textarea>
          <div className="notes-buttons">
            <Button onClick={saveNotes} variant="contained" style={{ marginRight: '10px' }} startIcon={<SaveIcon/>}>
              Save Note
            </Button>
            <Button onClick={toggleNotesModal} variant="contained" startIcon={<ViewListIcon/>}>
              View All Notes
            </Button>
          </div>

          <Dialog open={isNotesModalOpen} onClose={toggleNotesModal}>
            <DialogContent className="notes-container">
              <List>
                {Array.isArray(siteNotesList) && siteNotesList.length > 0 ? (
                  // Sort notes before rendering
                  [...siteNotesList]
                    .sort((a, b) => (b.timeStamp?.seconds || 0) - (a.timeStamp?.seconds || 0))
                    .map((note) => (
                      <ListItem key={note.timeStamp?.seconds}>
                        <ListItemText
                          primary={note.content || "No content"} // Default to "No content" if missing
                          secondary={`${note.author || "Unknown"} - ${
                            new Date(note.timeStamp?.seconds * 1000).toLocaleString() || "Unknown time"
                          }`}
                        />
                      </ListItem>
                    ))
                ) : (
                  <p>No notes available.</p>
                )}
              </List>
            </DialogContent>
          </Dialog>

      </div>

      <div className="dashboard-container">
        <div className="dashboard-main">
          <div className="dashboard-header">
              <h1>{siteName} - Overview</h1>
              {/* Dropdown for Date Selection */}
              <div className="date-selection">
                <label htmlFor="date-dropdown">Select Date: </label>
                <select 
                  id="date-dropdown" 
                  value={selectedDate} 
                  onChange={handleDateChange}
                >
                  {availableDates.map((folder) => (
                    <option key={folder.date} value={folder.date}>
                      {formatDate(folder.date)}
                    </option>
                  ))}
                </select>
              </div>
          </div>
    
    {/*Actual dashboard part */}
        {/*Actual dashboard part */}
            {/*Actual dashboard part */}
                {/*Actual dashboard part */}

          <div className="dashboard-grid">
                {/* Pie Chart Section */}
                            {/* Pie Chart Section */}
                                          {/* Pie Chart Section */}
                                                   {/* Pie Chart Section */}
            <div className="dashboard-pie-chart">
              <h2>Fault Distribution</h2>
              <Pie data={generatePieData()} />
            </div>
    
    {/*Site Data tile card */}
        {/*Site Data tile card */}
            {/*Site Data tile card */}
                {/*Site Data tile card */}
                    {/*Site Data tile card */}

            <div className="dashboard-site-data" onClick={handleSectionClick}>
              <h2>Site Data</h2>

              <div className="metric-item metric-highlight-red">
                <p className="kWh-lost">Estimated Annual kWh Lost</p>
                <p className="metric-value">{siteData.kWhLost ? `${formatNumberWithCommas(siteData.kWhLost)} kWh` : 'Calculating...'}</p>
              </div>

              <div className="metric-item metric-highlight-red">
                <p className="revenue-lost">Estimated Annual Revenue Lost:</p>
                <p className="metric-value">{revenueLost ? `$${formatNumberWithCommas(revenueLost)}` : 'Calculating...'}</p>
              </div>

              {/* Combined Impacted kW and Total Panels Affected */}
              <div className="metric-row">
                <div className="metric-item metric-highlight-red">
                  <p className="kwh-lost">Total Panels Affected</p>
                  <p className="metric-value">{formatNumberWithCommas(siteData.totalPanelsAffected)}</p>
                </div>
                <div className="metric-item metric-highlight-red">
                  <p>Impacted kW</p>
                  <p className="metric-value">{formatNumberWithCommas(impactedKW)} kW</p>
                </div>
              </div>

              <div className="metric-item metric-highlight-red">
                <p className="percent-affected">Percent Affected</p>
                <p className="metric-value">{siteData.percentAffected}%</p>
              </div>

              <div className="metric-item metric-combined-orange">
                <p className="site-location">Site Location</p>
                <p>{siteData.center.lat}, {siteData.center.lng}</p>

                <p className="total-panels">Total Panels</p>
                <p>{formatNumberWithCommas(siteData.panelCount)}</p>

                <p className="total-faults">Total Faults</p>
                <p>{formatNumberWithCommas(siteData.statistics.totalFaultsFound)}</p>
              </div>

              <p style={{ fontSize: '0.8em', color: 'gray', marginTop: '10px' }}>
                *These numbers are estimates based on PVWatts calculations and may not reflect actual energy production or revenue.
              </p>
            </div>

      {/* Fault level card */}
            {/* Fault level card */}
                  {/* Fault level card */}
                        {/* Fault level card */}

            <div className="dashboard-fault-level-card">
              <h2>
                Fault Level Breakdown
                <div className="info-icon-container">
                  <span 
                    className="info-icon" 
                    title="Hover for more info"
                    onMouseEnter={() => setIsInfoHovered(true)} 
                    onMouseLeave={() => setIsInfoHovered(false)} 
                  >
                    <InfoIcon fontSize="small" color="action" />
                  </span>
                </div>
              </h2>

              <div className="levels-summary-vertical">
                {isInfoHovered ? (
                  <div className="info-content">
                    <h3>Failure Definition and Impact</h3>

                    {/* Level 1 */}
                    <div className="info-level">
                      <h4>Level 1</h4>
                      <table>
                        <tbody>
                          <tr><td>String Failed</td><td>String</td></tr>
                          <tr><td>Combiner Field</td><td>Field</td></tr>
                          <tr><td>Inverter Failed</td><td>Inverter</td></tr>
                          <tr><td>Shading</td><td>String</td></tr>
                        </tbody>
                      </table>
                    </div>

                    {/* Level 2 */}
                    <div className="info-level">
                      <h4>Level 2</h4>
                      <table>
                        <tbody>
                          <tr><td>Tracker Not Aligned</td><td>Tracker Field</td></tr>
                          <tr><td>Inverter Therm. Issues</td><td>Inverter</td></tr>
                          <tr><td>Optimizer MPPT failing</td><td>String</td></tr>
                          <tr><td>Soiling</td><td>String</td></tr>
                          <tr><td>Broken Module</td><td>Module</td></tr>
                        </tbody>
                      </table>
                    </div>

                    {/* Level 3 */}
                    <div className="info-level">
                      <h4>Level 3</h4>
                      <table>
                        <tbody>
                          <tr><td>Module Hotspot</td><td>Module</td></tr>
                          <tr><td>Module Mult. Hotspot</td><td>Module</td></tr>
                          <tr><td>Module Diode Issue</td><td>Module</td></tr>
                          <tr><td>Module Missing</td><td>Module</td></tr>
                          <tr><td>Mod. Moister Ingress</td><td>Module</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  getFilteredLevelsData().map(({ level, categories }) => {
                    const { affectedPanels, percentOfSite } = getAffectedPercentage({ categories });
                    let color = 'yellow';
                    if (percentOfSite > 1 && percentOfSite < 3) color = 'orange';
                    else if (percentOfSite >= 3) color = 'red';

                    return (
                      <div
                        key={level}
                        className={`level-box ${color} ${expandedLevel === level ? 'expanded' : ''}`}
                        onClick={() => toggleExpandLevel(level)}
                      >
                        <h3>{level}</h3>
                        <p>{affectedPanels} panels affected</p>
                        <p className="small-text">{percentOfSite}% of site</p>
                        <div className="expanded-content">
                          {expandedLevel === level && (
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Panels Affected</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {categories.map(({ categoryName, affectedPanels }, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{decodeURIComponent(categoryName)}</TableCell>
                                      <TableCell>{affectedPanels}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>


              {/* Dots and table */}
                            {/* Dots and table */}
                                          {/* Dots and table */}
                                                        {/* Dots and table */}
                                                                      {/* Dots and table */}

    
              <div className="dashboard-data-table" style={{ maxHeight: '400px', overflowY: 'auto', position: 'relative' }}>
                <h2>Inverter Block Data</h2>
                <Button 
                  onClick={() => setIsVisualMode(!isVisualMode)} 
                  style={{ position: 'absolute', top: '10px', right: '10px' }}
                  startIcon={isVisualMode ? <TableRowsIcon /> : <CircleIcon />}
                >
                  {isVisualMode ? "Table" : "Dots"}
                </Button>

                {/* Render the tooltip element */}
                {tooltipVisible && tooltipData && (
                  <div 
                    className="row-tooltip" 
                    style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
                  >
                    <p><strong>Inverter Block {tooltipData.inverterBlock.replace('IB', '')}</strong></p>
                    <p>Total Panels: {tooltipData.totalPanels}</p>
                    <p>Affected Panels: {tooltipData.affectedPanels}</p>
                    <p>% Affected: {tooltipData.percentageAffected}%</p>
                  </div>
                )}

                <div onClick={handleToggleTooltip}>
                  <CSSTransition
                    in={isVisualMode}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                  >
                    <div key="circleView">
                      {tableData.map((row, index) => (
                        <div 
                          key={index} 
                          className="visual-row"
                          onMouseMove={(e) => handleMouseMove(e, row)}
                          onMouseLeave={() => setTooltipData(null)} // Hides tooltip when not hovering
                        >
                          <span className="inverter-block-label">Inverter Block {row.inverterBlock.replace('IB', '')}</span>
                          <div className="circle-row">
                            {Array.from({ length: calculateCircles(row.totalPanels, row.affectedPanels).redCircles }, (_, i) => (
                              <span key={`red-${i}`} className="circle red-circle" />
                            ))}
                            {Array.from({ length: calculateCircles(row.totalPanels, row.affectedPanels).greenCircles }, (_, i) => (
                              <span key={`green-${i}`} className="circle green-circle" />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </CSSTransition>

                  <CSSTransition
                    in={!isVisualMode}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                  >
                    <TableContainer component={Paper} key="tableView">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell onClick={() => sortTable('inverterBlock')}>
                              Inverter Block {sortConfig.key === 'inverterBlock' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </TableCell>
                            <TableCell onClick={() => sortTable('totalPanels')}>
                              Total Panels {sortConfig.key === 'totalPanels' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </TableCell>
                            <TableCell onClick={() => sortTable('affectedPanels')}>
                              Affected Panels {sortConfig.key === 'affectedPanels' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </TableCell>
                            <TableCell onClick={() => sortTable('percentageAffected')}>
                              % Affected {sortConfig.key === 'percentageAffected' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.inverterBlock}</TableCell>
                              <TableCell>{row.totalPanels}</TableCell>
                              <TableCell>{row.affectedPanels}</TableCell>
                              <TableCell>{row.percentageAffected}%</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CSSTransition>
                </div>
              </div>
          </div>

          {/* Dark Mode Switch */}
          <div className="dark-mode-toggle">
            {isDarkMode ? <Brightness3 /> : <WbSunny />} 
            <Switch 
              checked={isDarkMode} 
              onChange={handleDarkModeToggle}
              color="default"
            />
          </div>

          {/* Footer Section */}
          <div className="dashboard-footer">
            &copy; {new Date().getFullYear()} HorizonView Technologies
          </div>

        </div>
      </div>
    </div>
  );  
};

export default SiteDashboard;
